// Globals
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledBadge,
  StyledButton,
  StyledInfoWrapper,
  StyledRoundArrowsIcon,
  StyledTransferButton,
} from './FundingRoundDetailsCardStyles';
import PayoutTransferModal from './PayoutTransferModal';
import { StyledText } from '../Styled';
import { showErrorToast } from '@/components';

// Models
import { IFundingPayoutDetails, PayoutStatuses } from '@/models/payout.model';
import { CampaignStatuses } from '@/models/campaigns.model';
import { RequestStatuses } from '@/models/requests.model';
import { WalletNetworksEnum } from '@/models/web3.model';

// Helpers
import { useAppDispatch, useModal } from '@/hooks';
import { payoutFundingRoundTransaction } from '../../feature/actionCreators';

interface IPayoutActionsBadges {
  data: IFundingPayoutDetails;
}

const PayoutActionsBadges = ({ data }: IPayoutActionsBadges) => {
  const { t } = useTranslation(['payout']);
  const dispatch = useAppDispatch();
  const { isOpened, openModal, closeModal } = useModal();
  const { fundingRoundId, id: payoutId, sk: payoutSk } = data;
  const isCreditEthereumUsed = data?.network === WalletNetworksEnum.ETHEREUM;

  const startTransaction = useCallback(() => {
    if (data?.payoutRequest?.status !== RequestStatuses.IN_PROGRESS) {
      return showErrorToast({ message: t('payout_status_error') });
    }
    dispatch(
      payoutFundingRoundTransaction({ fundingRoundId, payoutId, payoutSk }),
    );
  }, [data]);

  const transactionButtonAction = isCreditEthereumUsed
    ? openModal
    : startTransaction;

  if (data?.payoutTransaction?.status) {
    return (
      <StyledInfoWrapper>
        <StyledBadge $status={data?.payoutTransaction?.status}>
          <StyledText>
            {t(`payout_${data?.payoutTransaction?.status}_title`)}
          </StyledText>
        </StyledBadge>
        {data?.payoutTransaction?.status === PayoutStatuses.REJECTED && (
          <StyledButton type="ghost" onClick={startTransaction}>
            {t('payout_try_again_button')}
            <StyledRoundArrowsIcon />
          </StyledButton>
        )}
      </StyledInfoWrapper>
    );
  }

  return (
    <StyledInfoWrapper>
      <StyledTransferButton
        type="primary"
        onClick={transactionButtonAction}
        disabled={data?.campaignStatus === CampaignStatuses.DELIVERY_FINISHED}
      >
        {t('payout_start_transaction_button')}
      </StyledTransferButton>

      <PayoutTransferModal
        isOpened={isOpened}
        handleClose={closeModal}
        startTransaction={startTransaction}
        walletAddress={data?.walletAddress}
      />
    </StyledInfoWrapper>
  );
};

export default PayoutActionsBadges;
