import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { PAYOUT_SLICE_NAME } from './models';

import { CampaignsAPI, FundingRoundAPI, PayoutAPI } from '@/api';
import { formatApiError } from '@/utils';
import {
  IFundingPayoutDetails,
  IFundingPayoutListItem,
  IFundingRoundRequestDTO,
  IPayoutDetails,
  IPayoutDetailsListDTO,
  IPayoutDetailsListResponse,
  IPayoutFundingRoundTransactionDTO,
  IPayoutList,
  IPayoutTransactionDTO,
} from '@/models/payout.model';
import { ICampaign } from '@/models/campaigns.model';
import { IFundingRound } from '@/models/requests.model';
import {
  FundingRoundParticipantsReportItem,
  FundingRoundParticipantsReportResponse,
  FundingRoundParticipationRefundTransactionRequestDTO,
} from '@/models/reports.model';

export const getPayoutList = createAsyncThunk<
  IPayoutList[],
  void,
  { serializedErrorType: IApiError }
>(
  `${PAYOUT_SLICE_NAME}/getPayoutList`,
  async () => {
    const response = await PayoutAPI.getPayoutList();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingPayoutList = createAsyncThunk<
  IFundingPayoutListItem[],
  void,
  { serializedErrorType: IApiError }
>(
  `${PAYOUT_SLICE_NAME}/getFundingPayoutList`,
  async () => {
    const response = await PayoutAPI.getFundingPayoutList();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingRefundList = createAsyncThunk<
  IFundingPayoutListItem[],
  void,
  { serializedErrorType: IApiError }
>(
  `${PAYOUT_SLICE_NAME}/getFundingRefundList`,
  async () => {
    const response = await PayoutAPI.getFundingRefundList();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getPayoutDetailsList = createAsyncThunk<
  IPayoutDetailsListResponse,
  IPayoutDetailsListDTO,
  { serializedErrorType: IApiError }
>(
  `${PAYOUT_SLICE_NAME}/getPayoutDetailsList`,
  async (data: IPayoutDetailsListDTO) => {
    const response = await PayoutAPI.getPayoutDetailsList({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const payoutTransaction = createAsyncThunk<
  IPayoutDetails,
  IPayoutTransactionDTO,
  { serializedErrorType: IApiError }
>(
  `${PAYOUT_SLICE_NAME}/payoutTransaction`,
  async (data) => {
    const response = await PayoutAPI.payoutTransaction(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const payoutFundingRoundTransaction = createAsyncThunk<
  IFundingPayoutDetails[],
  IPayoutFundingRoundTransactionDTO,
  { serializedErrorType: IApiError }
>(
  `${PAYOUT_SLICE_NAME}/payoutFundingRoundTransaction`,
  async (data) => {
    const response = await PayoutAPI.payoutFundingRoundTransaction(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const refundFundingRoundTransaction = createAsyncThunk<
  FundingRoundParticipantsReportItem,
  FundingRoundParticipationRefundTransactionRequestDTO,
  { serializedErrorType: IApiError }
>(
  `${PAYOUT_SLICE_NAME}/refundFundingRoundTransaction`,
  async (data) => {
    const response = await PayoutAPI.refundFundingRoundTransaction(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingPayoutDetailsList = createAsyncThunk<
  IFundingPayoutDetails[],
  IFundingRoundRequestDTO,
  { serializedErrorType: IApiError }
>(
  `${PAYOUT_SLICE_NAME}/getFundingPayoutDetailsList`,
  async (data) => {
    const response = await PayoutAPI.getFundingPayoutDetailsList(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingRefundDetails = createAsyncThunk<
  FundingRoundParticipantsReportResponse,
  IFundingRoundRequestDTO,
  { serializedErrorType: IApiError }
>(
  `${PAYOUT_SLICE_NAME}/getFundingRefundDetailsList`,
  async (data) => {
    const response = await PayoutAPI.getFundingRefundDetails(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const completeCampaign = createAsyncThunk<
  ICampaign,
  string,
  {
    serializedErrorType: IApiError;
  }
>(
  `${PAYOUT_SLICE_NAME}/completeCampaign`,
  async (data) => {
    const response = await CampaignsAPI.campaignCompletion(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const completeFundingRound = createAsyncThunk<
  IFundingRound,
  string,
  {
    serializedErrorType: IApiError;
  }
>(
  `${PAYOUT_SLICE_NAME}/completeFundingRound`,
  async (data) => {
    const response = await FundingRoundAPI.fundingRoundCompletion(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const sendPayoutRequest = createAsyncThunk<
  IPayoutDetails,
  string,
  {
    serializedErrorType: IApiError;
  }
>(
  `${PAYOUT_SLICE_NAME}/sendPayoutRequest`,
  async (data) => {
    const response = await PayoutAPI.sendPayoutRequest(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const sendFundingPayoutRequest = createAsyncThunk<
  void,
  string,
  {
    serializedErrorType: IApiError;
  }
>(
  `${PAYOUT_SLICE_NAME}/sendFundingPayoutRequest`,
  async (data) => {
    const response = await PayoutAPI.sendFundingPayoutRequest(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
