import { SocialChannels } from './socialChannel.model';
import { IGeographies } from './dataLists.model';
import {
  IProfileCommunity,
  MarketingAffiliateCommission,
} from './kolsList.model';
import {
  DocumentTypes,
  SigningTypes,
  DocumentStatuses,
} from './documents.model';
import { IGetListParamsBase } from './api.model';
import { IMainContact } from '@/models/sharedProfile.model';
import { KYCStatuses } from '@/constants';
import { CryptoCurrenciesEnum, WalletNetworksEnum } from '@/models/web3.model';
import { TAmountByCurrency } from '@/models/reports.model';

export enum ProjectsEndpoints {
  GET_PROJECTS = '/admin/projects',
}

export interface IPerson {
  firstName: string;
  lastName: string;
  position: string;
  address: string;
  country: string;
  city: string;
  zipCode: string;
}

export interface ICompanyInfo {
  address?: string;
  country?: string;
  city?: string;
  fullName?: string;
  companyName?: string;
  companyType?: string;
  registrationNumber?: string;
}

export enum VerificationLevel {
  RegularListing = 'regular_listing',
  BySeedhunter = 'by_seedhunter',
}

export interface IKYB {
  state: KYCStatuses;
  sessionId: string;
  reason: string | null;
  address: {
    country: string;
    city: string;
    address: string;
  };
  fullName?: string;
  nationality?: string;
  documentNumber?: string;
  title?: string;
  registration?: string;
  registrationNumber?: string;
}

export interface IWallet {
  walletAddress?: string;
  walletCurrency?: CryptoCurrenciesEnum;
  walletNetwork?: WalletNetworksEnum;
}

export interface IDocument {
  id: string;
  type: DocumentTypes;
  signingType: SigningTypes;
  status: DocumentStatuses;
  clickwrapId: string;
  agreementId: string;
  envelopeId: string;
  signedOn: string;
  declinedOn: string;
  version: string;
  versionId: string;
  versionNumber: string;
}

export interface IResources {
  fileName: string;
  link: string;
}

export enum SeedHunterWalletCreationStatus {
  MOT_CREATED = 'not_created',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
  SUCCESS = 'success',
}

export interface ISocialChannel {
  id?: string;
  description?: string;
  investmentTarget?: number;
  name?: string;
  link?: string;
  followers?: number;
  price?: number;
}

export enum ProjectsPhase {
  INVESTMENT_STARTED = 'investment_started',
  INVESTMENT_CLOSED = 'investment_closed',
  FINALIZED = 'finalized',
}

export interface IInvitedBy {
  id: string;
  role: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface ICampaignParticipation {
  id: string;
  investedMarketing: number;
  status: string;
  alias?: string;
  title?: string;
  marketingPayout?: number;
  socialChannels?: ISocialChannel[];
  document?: string;
  mainCommunity?: string;
}

export interface IProject extends IWallet {
  id?: string;
  status: string;
  firstName: string;
  lastName: string;
  formAddress?: string;
  email?: string;
  mainContact?: IMainContact;
  title?: string;
  subtitle?: string;
  description?: string;
  companyType?: string;
  verificationLevel?: VerificationLevel;
  contactPerson?: IPerson;
  companyInfo?: ICompanyInfo;
  mainCommunity?: IProfileCommunity;
  secondaryCommunity?: IProfileCommunity;
  mainGeography?: IGeographies;
  secondaryGeography?: IGeographies;
  projectImage?: string;
  website?: string;
  whitepaper?: string;
  KYB?: IKYB;
  walletAddress?: string;
  walletCurrency?: CryptoCurrenciesEnum;
  walletNetwork?: WalletNetworksEnum;
  savedTransactionCurrency: CryptoCurrenciesEnum;
  savedTransactionNetwork: WalletNetworksEnum;
  documents?: IDocument[];
  resources?: IResources[];
  seedHunterWalletAddress?: string;
  seedHunterWalletNetwork?: WalletNetworksEnum;
  seedHunterWalletCreationStatus?: SeedHunterWalletCreationStatus;
  socialChannel?: ISocialChannel[];
  participation?: ICampaignParticipation[];
  totalAffiliateCommissionAmount?: number;
  affiliateCode?: string;
  linkClicks?: number;
  registrationsCompleted?: number;
  investedMoneyAmount?: number;
  investmentTarget?: number;
  phase?: ProjectsPhase;
  verifiedAt?: string;
  created?: string;
  modified?: string;
  role: string;
  unreadRequestsCount: number;
  invitedBy?: IInvitedBy;
  seedHunterWalletBalance?: TAmountByCurrency;
  reason?: string;
  allowedSocialChannels?: SocialChannels[];
}

export interface IContractInfo {
  title?: string;
  description?: string;
}

// ============= DTO =============

export interface IGetProjectsListDTO extends IGetListParamsBase {
  search?: string;
}

export interface IUpdateProjectDetailsDTO {
  id?: string;
  formAddress?: string;
  firstName?: string;
  lastName?: string;
  mainContact?: IMainContact;
  title?: string;
  subtitle?: string;
  description?: string;
  projectImage?: string;
  website?: string;
  whitepaper?: string;
  walletAddress?: string;
  walletCurrency?: CryptoCurrenciesEnum;
  verificationLevel?: VerificationLevel;
  walletNetwork?: WalletNetworksEnum;
  companyType?: string;
  mainCommunity?: IProfileCommunity;
  secondaryCommunity?: IProfileCommunity;
  mainGeography?: IGeographies;
  secondaryGeography?: IGeographies;
  contactPerson?: IPerson;
  socialChannel?: ISocialChannel[];
  allowedSocialChannels?: SocialChannels[];
  reason?: string;
  status?: string;
}

export interface IGetContractInfoDTO {
  projectId?: string;
  documentType?: DocumentTypes;
  signingType?: SigningTypes;
}

export interface IContractInfoDTO {
  id?: string;
  isProject?: boolean;
  documentType?: string;
  descriptionForProject?: string;
  descriptionForKol?: string;
  items?: IContractInfo[];
}

export interface IGetProjectAffiliateDTO {
  startSk?: string;
  projectId?: string;
  limit?: number;
}

export interface ICreateProjectWalletDTO {
  id?: string;
}

// ============== Response ==============

export interface IProjectsListResponse {
  items: IProject[];
  hasMore: boolean;
}

export interface IProjectDetailsResponse {
  data: IProject;
}

export interface IContractInfoResponse {
  documentType?: DocumentTypes;
  isPreviewed?: boolean;
  descriptionForProject?: string;
  descriptionForKol?: string;
  items: IContractInfo[];
}

export interface IContractInfoPreviewResponse {
  url: string;
}

export interface IGetProjectAffiliateResponse {
  items: MarketingAffiliateCommission[];
  hasMore: boolean;
}

export interface ICreateProjectWalletResponse {
  message?: string;
}
